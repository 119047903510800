<template>
  <div id="Story" class="container-xl header-large FoundersGrotesk">
    <div class="desktop">
      <div class="lg-text text-uppercase">Our Story</div>
      <div v-for="(rowData, index) in dataRows" :key="index" class="mb-5 h-100">
        <b-row class="h-100">
          <b-col
            class="h-100 m-0 p-0"
            cols="12"
            md="6"
            :order="rowData.reverseColumns ? 2 : 1"
          >
            <img
              :src="rowData.imageSrc"
              :alt="rowData.imageAlt"
              class="d-block img-fluid border-img"
            />
          </b-col>
          <b-col cols="12" md="6" :order="rowData.reverseColumns ? 1 : 2">
            <div class="center-text">
              <div
                v-html="formatText(rowData.text)"
                class="md-text fade-in-up-element"
              ></div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="mobile">
      <div class="lg-text text-uppercase">Our Story</div>
      <div
        v-for="(rowData, index) in dataRowsMobile"
        :key="index"
        class="mb-5 h-100"
      >
        <b-row class="h-100">
          <b-col
            class="h-100 m-0 p-0"
            cols="12"
            md="6"
            :order="rowData.reverseColumns ? 2 : 1"
          >
            <img
              :src="rowData.imageSrc"
              :alt="rowData.imageAlt"
              class="d-block img-fluid border-img"
            />
          </b-col>
          <b-col cols="12" md="6" :order="rowData.reverseColumns ? 1 : 2">
            <div class="center-text">
              <div
                v-html="formatText(rowData.text)"
                class="md-text fade-in-up-element"
              ></div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      dataRows: [
        {
          imageSrc: "/img/home/OurStory_FamilyPhotoA.jpg",
          imageAlt: "Image 1",
          text: "Ode was <b> started by a family </b> who fell in love with a street: Dundas St. West. \n\n We dreamt of creating a place that <b> celebrates the best of what the neighbourhood is </b> becoming and what it once was. So we made Ode for people like us. \n\n That means <b> we’re not the Hyatt, the Marriott, or any of the Four Seasons. </b> We cater only to our guests and answer only to our mother. This business is our baby, so <b> we are dedicated to creating the best experience </b> for everyone who walks through our front door.",
          reverseColumns: false,
        },
        {
          imageSrc: "/img/home/OurStory_DundasStreet.jpg",
          imageAlt: "Image 2",
          text: "Dundas West also known as “Little Portugal” is neither Little, nor is it Portugal. <b> It’s a neighbourhood where old world romance meets modern life: </b> you'll find Portuguese bakeries selling juicy pastel de nata’s, hole in the wall galleries featuring local art, thrift shops where you can find the perfect ill advised chapeau, and restaurants whose cuisine can only be described as unruly. \n\n Ode is a reflection of our neighbourhood and the people in it. <b> No beige carpets, no black and white photos of three pebbles in a pile. </b> Instead, modern rooms each with their own individual personality brought to life by local Toronto based artists, designers and manufacturers that we are proud to call our friends.",
          reverseColumns: true,
        },
        {
          imageSrc: "/img/home/OurStory_Facade.jpg",
          imageAlt: "Image 2",
          text: "<b> Staying at interesting places should be within reach </b> of those without a corporate credit card, and leave you with money in your pocket to explore. \n\n By stripping back the things that you don’t need (think bathroom telephones, branded water, sewing kits), <b> we are able to provide you with more of the things you do need, at an affordable price. </b> \n\n Collect happy memories, not loyalty points.",
          reverseColumns: false,
        },
      ],
      dataRowsMobile: [
        {
          imageSrc: "/img/home/OurStory_FamilyPhotoA.jpg",
          imageAlt: "Image 1",
          text: "Ode was <b> started by a family </b> who fell in love with a street: Dundas St. West. \n\n We dreamt of creating a place that <b> celebrates the best of what the neighbourhood is </b> becoming and what it once was. So we made Ode for people like us. \n\n That means <b> we’re not the Hyatt, the Marriott, or any of the Four Seasons. </b> We cater only to our guests and answer only to our mother. This business is our baby, so <b> we are dedicated to creating the best experience </b> for everyone who walks through our front door.",
          reverseColumns: false,
        },
        {
          imageSrc: "/img/home/OurStory_DundasStreet.jpg",
          imageAlt: "Image 2",
          text: "Dundas West also known as “Little Portugal” is neither Little, nor is it Portugal. <b> It’s a neighbourhood where old world romance meets modern life: </b> you'll find Portuguese bakeries selling juicy pastel de nata’s, hole in the wall galleries featuring local art, thrift shops where you can find the perfect ill advised chapeau, and restaurants whose cuisine can only be described as unruly. \n\n Ode is a reflection of our neighbourhood and the people in it. <b> No beige carpets, no black and white photos of three pebbles in a pile. </b> Instead, modern rooms each with their own individual personality brought to life by local Toronto based artists, designers and manufacturers that we are proud to call our friends.",
          reverseColumns: false,
        },
        {
          imageSrc: "/img/home/OurStory_Facade.jpg",
          imageAlt: "Image 2",
          text: "<b> Staying at interesting places should be within reach </b> of those without a corporate credit card, and leave you with money in your pocket to explore. \n\n By stripping back the things that you don’t need (think bathroom telephones, branded water, sewing kits), <b> we are able to provide you with more of the things you do need, at an affordable price. </b> \n\n Collect happy memories, not loyalty points.",
          reverseColumns: false,
        },
      ],
    };
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.header-large {
  padding: 50px 0px 100px 0px;
  position: relative;
  @media screen and (max-width: 1176px) {
    padding: 40px 40px 50px 40px;
  }
  .center-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media screen and (min-width: 900px) {
      padding: 0px 35px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 0px 0px 0px;
    }
  }
  img {
    width: 555px;
  }
  .border-img {
    border: 2px solid black;
  }
  .mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .desktop {
    display: block;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
</style>
